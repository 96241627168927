.App {
  text-align: center;
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 4em;
}

.menu {
  font-size: 48px
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 15%;
}

.leftheader {
  order:0 ;
  width: 50%;
  position: relative;
  float: left;
  align-items: flex-start;
}

.rightheader {
  order:1 ;
  width: 50%;
  position: relative;
  float: right;
  align-items: flex-end;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}

.content {
  order: 0;
  flex-grow: 4;
  flex-shrink: 4;
  width: 80%;
  height: 85%;
  padding: 20px;
}

.sidebar {
  padding: 15px;
  padding-top: 50px;
  order: 1;
  flex-grow: 1;
  flex-basis: 20%;
  width: 20%;
  height: 85%;
}

.content66 {
  order: 0;
  flex-grow: 4;
  flex-shrink: 4;
  width: 66%;
  height: 85%;
  padding: 20px;
}

.sidebar34 {
  padding: 15px;
  padding-top: 50px;
  order: 1;
  flex-grow: 1;
  flex-basis: 34%;
  width: 34%;
  height: 85%;
}

.content70 {
  order: 0;
  flex-grow: 4;
  flex-shrink: 4;
  width: 70%;
  height: 85%;
  padding: 20px;
}

.sidebar30 {
  padding: 15px;
  padding-top: 50px;
  order: 1;
  flex-grow: 1;
  flex-basis: 30%;
  width: 30%;
  height: 85%;
}

.csv-reader {
  min-height: 300px;
  min-width: 90%;
}

.footer {
  height: 4em;
  position: relative;
  float: right;
  align-items: flex-end;
}

.graph {
  width: 100%;
}

.tall-graph {
  height: 750px;
  width: 100%;
}

.Footer-Menu {
  position: relative;
  float: right;
}

.panel-button {
  min-width: 50%;
  max-width: 90%;
}

.panel-list {
  min-height: 40%;
  max-height: 40%;
}